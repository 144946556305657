.tabs-component__tabs {
  display: flex;
  justify-content: center;
  gap: var(--space-4);
  width: -moz-fit-content;
  width: fit-content;
  margin: var(--space-32) auto;
  padding: var(--space-2);
}
.tabs-component__tab {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-blue-200);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
}
.tabs-component__tab:hover {
  background-color: var(--cta-bg-hover);
}
.tabs-component__tab:active {
  background-color: var(--cta-bg-active);
}
.tabs-component__tab[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}
.tabs-component__tab[aria-selected=false] {
  --cta-bg: #fff;
  --cta-color: var(--color-blue-1100);
  --cta-bg-hover: var(--color-neutral-300);
  --cta-bg-active: #e3e6e9;
  --cta-bg-disabled: var(--color-neutral-200);
  --cta-color-disabled: #969da4;
  --cta-color-border: var(--color-neutral-600);
  --cta-color-border-disabled: var(--cta-color-disabled);
  border: 1px solid var(--cta-color-border);
}
.tabs-component__tab[aria-selected=true] {
  --cta-bg: var(--color-blue-1100);
  --cta-color: #fff;
  --cta-bg-hover: #012d3d;
  --cta-bg-disabled: var(--color-blue-400);
}
@media (max-width: 767px) {
  .tabs-component__tab {
    padding: 0.625rem;
  }
}
@media (min-width: 768px) {
  .tabs-component__tab {
    padding: var(--space-8) var(--space-40);
  }
}